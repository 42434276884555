import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import isAbsoluteURL from 'is-absolute-url';
import React from 'react';

import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import { Button as MuiButton, ButtonOwnProps, Chip, Stack, useTheme } from '@mui/material';

export interface HomepageLink {
  id: string
  href: string
  url: string
  text: string
  contentful_id: string
}

// interface ContentfulAssetImageProps
//   extends Omit<Omit<GatsbyImageProps, "alt">, "image"> {
//   asset: ContentfulAsset
//   alt?: string
//   aboveFold?: boolean
//   width?: string,
//   height?: string,
//   contentfulPreviewProps?: any
// }

// export type ContentfulAsset = {
//   id: string
//   title?: string
//   url?: string
//   contentful_id: string
//   gatsbyImageData?: IGatsbyImageData
// }

/// Supports SVGs, childImageSharp is null for SVGs
// export function ContentfulAssetImage({
//   asset,
//   loading,
//   alt,
//   style,
//   imgStyle,
//   aboveFold,
//   width,
//   height,
//   contentfulPreviewProps,
// }: ContentfulAssetImageProps) {
//   return !aboveFold && asset && asset.gatsbyImageData ? (
//     <GatsbyImage
//       {...contentfulPreviewProps}
//       loading={loading || "lazy"}
//       image={asset.gatsbyImageData}
//       width={width}
//       height={height}
//       alt={alt || asset.title}
//     />
//   ) : asset && asset.url ? (
//     <img
//       style={style || imgStyle}
//       {...contentfulPreviewProps}
//       width={imgStyle && imgStyle.width ? imgStyle.width : (width ? width : undefined)}
//       height={imgStyle && imgStyle.height ? imgStyle.height : (height ? height : undefined)}
//       src={asset.url}
//       alt={alt || (asset && asset.title)}
//     />
//   ) : (
//     <></>
//   )
// }

type KickerProps = {
  // icon?: ContentfulAsset
  label: string
  background?: string
}

export function Kicker({ background, ...props }: KickerProps) {
  const theme = useTheme();

  return (
    <>
      <Chip
        {...props}
        // icon={
        //   icon ? (
        //     <ContentfulAssetImage
        //       asset={icon}
        //       alt={props.label}
        //       {...icon}
        //       imgStyle={{ height: "20px", width: "20px" }}
        //     />
        //   ) : undefined
        // }
        label={props.label}
        sx={{
          padding: "0.5rem 1rem",
          background: background || theme.palette.primary.light,
          fontWeight: 500,
          color: theme.palette.primary.contrastText,
          fontSize: "1rem",
          lineHeight: "1.5rem",
        }}
        variant="filled"
        color="primary"
      />
    </>
  )
}

interface LinkProps {
  to?: string
  href?: string
}

export function Link(
  { to, href, ...props }: LinkProps,
  ref: React.ForwardedRef<unknown>,
) {
  const url = href || to || ""
  if (isAbsoluteURL(url)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a href={url} target="_blank" {...props} />
    )
  }
  return <GatsbyLink to={url} {...props} />
}

type NavLinkProps = any & {
  contentfulPreviewProps?: any
}

type NavButtonLinkProps = any & {
  contentfulPreviewProps?: any
}


export function NavLink({ contentfulPreviewProps, ...props }: NavLinkProps) {
  const link = React.forwardRef<unknown, LinkProps>((props, ref) =>
    Link(props, ref),
  )
  return <MuiButton variant="text" component={link} {...contentfulPreviewProps} {...props} />
}

export function NavButtonLink({ contentfulPreviewProps, ...props }: NavButtonLinkProps) {
  return <MuiButton variant="text" {...contentfulPreviewProps} {...props} />
}

type ButtonProps = ButtonOwnProps & {
  href?: string
  to?: string
  contentfulPreviewProps?: any
  onClick?: () => void
}

export function Button({
  color = "primary",
  variant = "contained",
  href,
  to,
  sx,
  fullWidth,
  children,
  contentfulPreviewProps,
  ...other
}: ButtonProps) {
  const url = href || to
  const hasUrl = Boolean(url);

  const isAbsolute = hasUrl && isAbsoluteURL(url)

  return isAbsolute || !hasUrl ?
    <MuiButton
      variant={variant}
      color={color}
      href={url}
      rel='noopener noreferrer'
      target="_blank"
      fullWidth={fullWidth}
      sx={sx}
      {...contentfulPreviewProps}
      {...other}
    >
      {children}
    </MuiButton> :
    <GatsbyLink to={url}>
      <MuiButton
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        sx={sx}
        {...contentfulPreviewProps}
        {...other}
      >
        {children}
      </MuiButton>
    </GatsbyLink>
}

interface ButtonListProps {
  links: HomepageLink[]
  reversed?: boolean
  buttonVariant?: "text" | "outlined" | "contained"
}
interface ButtonListItemProps {
  link: HomepageLink
  buttonVariant?: "text" | "outlined" | "contained"
}

export function ButtonList({
  links = [],
  reversed = false,
  ...props
}: ButtonListProps) {
  if (!links || links.length === 0) return null
  return (
    <Stack>
      {links &&
        links.map((link, i) => (
          <div key={link.id}>
            <ButtonListItem {...props} link={link} />
          </div>
        ))}
    </Stack>
  )
}

export function ButtonListItem({
  link,
  ...props
}: ButtonListItemProps) {
  const inspectorProps = useContentfulInspectorMode();
  const data = useContentfulLiveUpdates({
    ...link,
    sys: { id: link.contentful_id },
  });

  return (
    <Button href={data.href} variant={props.buttonVariant} {...inspectorProps({
      entryId: link.contentful_id,
      fieldId: 'text',
    })}>
      {data.text}
    </Button>
  )
}
