import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useMemo, useState } from 'react';

import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import styled from '@emotion/styled';
import { Box, Container, Input, Stack, TextField, Theme, Typography, useTheme } from '@mui/material';

import { notPrintable } from '../shared/styles/notPrintable';
import { baseLogoHeight, baseLogoWidth } from './header';
import NavItemGroup from './nav-item-group';
import { PaymentOptionIcon } from './payment/paymentOptionIcon';
import { NavLink } from './ui';
import logo from '../assets/img/saaskit-logo-grey.png'

const useStyles = (theme: Theme) => ({
  background: {
    background: theme.palette.background.paper,
    pt: { xs: 4, sm: 4, md: 10 },
    pb: { xs: 12, sm: 12, md: 10 },
    mt: { xs: 4, sm: 4, md: 10 },
  },
})

// const PaymentOptions = ({ paymentSettings }: { paymentSettings: Queries.FooterComponentQuery['shopifyStorefront']['shop']['paymentSettings'] }) => {
//   const all = useMemo(() => {
//     return [...paymentSettings.acceptedCardBrands, ...paymentSettings.supportedDigitalWallets]
//   }, [paymentSettings])

//   const iconStyle = {
//     fontSize: '3rem',
//     maxHeight: '80px'
//   }

//   return (
//     <Stack gap={2} direction="row" alignItems={'center'} justifyContent={'center'}>
//       {all.map(p => <PaymentOptionIcon key={p} option={p} sx={iconStyle} />)}
//     </Stack>
//   )
// }

export default function Footer({ location, isSiteFramed }) {
  const theme = useTheme();
  const styles = useStyles(theme)
  const [emailFooter, setEmailFooter] = useState('');

  // const footerDataQuery: Queries.FooterComponentQuery = useStaticQuery(graphql`
  //   query FooterComponent {
  //     layoutFooter: contentfulLayoutFooter {
  //       id
  //       contentful_id
  //       __typename
  //       logo {
  //         id
  //         title
  //         url
  //         contentful_id
  //         __typename
  //         gatsbyImageData(placeholder: NONE, width: 230)
  //       }
  //       images {
  //         id
  //         title
  //         url
  //         contentful_id
  //         __typename
  //         gatsbyImageData(placeholder: NONE, width: 100)
  //       }
  //       navItems {
  //         id
  //         contentful_id
  //         __typename
  //         ... on ContentfulNavItemGroup {
  //           name
  //           navItems {
  //             id
  //             href
  //             text              
  //             contentful_id
  //             __typename
  //           }
  //         }
  //       }
  //       copyright
  //     }
  //     shopifyStorefront {
  //       shop {
  //         paymentSettings {
  //           acceptedCardBrands
  //           supportedDigitalWallets
  //         }
  //       }
  //     }
  //   }
  // `)

  // const inspectorProps = useContentfulInspectorMode();
  // const footerData = useContentfulLiveUpdates({
  //   ...footerDataQuery.layoutFooter,
  //   sys: { id: footerDataQuery.layoutFooter.contentful_id },
  // });

  const NotPrintable = styled('div')(notPrintable);

  // const openNewsletterSignup = () => {
  //   const klav = (window as any).klaviyo;
  //   klav?.openForm('S8A58k');
  //   setTimeout(() => {
  //     const emailInputs = document.getElementsByName('email');
  //     if (emailInputs && emailInputs.length > 0) {
  //       const emailInput = emailInputs[0] as HTMLInputElement;
  //       emailInput.value = emailFooter;
  //     }
  //   }, 1000);
  // }

  return (
    isSiteFramed ? <></> :
      <>
        <NotPrintable>
          <Box component="footer" sx={styles.background}>
            <Container>
              <Stack direction="column" spacing={4}>
                {/* <Stack
                  gap={{ xs: 3, sm: 4, md: 6, lg: 12, xl: 20 }}
                  direction={{ xs: "column", md: "row" }}
                > */}
                {/* <Stack
                    direction={"column"}
                    spacing={2}
                    sx={{
                      maxWidth: {
                        xs: "inherit",
                        sm: "inherit",
                        md: "180px",
                        lg: "180px",
                        xl: "180px",
                      },
                    }}
                  > */}
                <NavLink to={"/"}>
                  <Box sx={{ display: "none" }}>Home</Box>
                  <img src={logo} style={{
                    width: baseLogoWidth + "px",
                    height: baseLogoHeight + "px",
                  }} />
                  {/* <ContentfulAssetImage
                        asset={footerData.logo}
                        objectFit="cover"
                        contentfulPreviewProps={inspectorProps({
                          entryId: footerDataQuery.layoutFooter.contentful_id,
                          fieldId: 'logo',
                        })}
                        imgStyle={{
                          width: baseLogoWidth + "px",
                          height: baseLogoHeight + "px",
                        }}
                      /> */}
                </NavLink>
                {/* </Stack> */}
                {/* <Stack
                    direction={"row"}
                    spacing={{ xs: 3, sm: 6, md: 8, lg: 10 }}
                    useFlexGap
                    flexWrap="wrap"
                    justifyContent={{
                      xs: "space-between",
                      sm: "space-between",
                      md: "flex-start",
                      lg: "flex-start",
                    }}
                    {...inspectorProps({
                      entryId: footerDataQuery.layoutFooter.contentful_id,
                      fieldId: 'navItems',
                    })}
                  >
                    {footerData.navItems &&
                      footerData.navItems.map((navItem) => (
                        <Box key={navItem.id}>
                          <NavItemGroup
                            disableDropDown={true}
                            name={navItem.name}
                            navItems={navItem.navItems.map((ni) => {
                              return {
                                ...ni, contentfulPreviewProps: inspectorProps({
                                  entryId: ni.contentful_id,
                                  fieldId: 'text',
                                })
                              }
                            })}
                            contentfulPreviewProps={inspectorProps({
                              entryId: navItem.contentful_id,
                              fieldId: 'navItems',
                            })}
                          />
                        </Box>
                      ))}
                  </Stack> */}
                {/* </Stack> */}

                <Stack direction='column' spacing={2}>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: {
                          xs: "center",
                          sm: "center",
                          md: "inherit",
                          lg: "inherit",
                          xl: "inherit",
                        },
                      }}
                    >
                      SaaSKit acknowledges the first nations people of the land we
                      live, work and operate.
                    </Typography>

                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{ marginTop: "20px" }}
                    >
                      © {new Date().getFullYear()} SaaSKit. All rights reserved.
                    </Typography>
                  </Box>

                  {/* <Box>
                    <PaymentOptions paymentSettings={footerDataQuery.shopifyStorefront.shop.paymentSettings} />
                  </Box> */}

                  {/* {footerData.images && (
                    <Stack
                      direction="row"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center">
                      {footerData.images.map((image) => (
                        <ContentfulAssetImage
                          key={image.id}
                          asset={image}
                          style={{ height: '100px', width: '100px' }}
                          contentfulPreviewProps={inspectorProps({
                            entryId: image.contentful_id,
                            fieldId: 'title',
                          })}
                        />
                      ))}
                    </Stack>
                  )} */}
                </Stack>
              </Stack>
            </Container>
          </Box>
        </NotPrintable>
      </>
  )
}
